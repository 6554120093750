import { registerTabManager } from "./modules/routerMainTab.js"
import routerSettings from "./modules/routerSettings.js"
import routerSettingsConfig from "./modules/routerSettingsConfig.js"
import modules from './modules/modules.js';

var settingsEnv = require("./modules/settingsEnv.json");
var locales = require.context("./modules/locales", false, /[a-z0-9-_]+\.json$/i);

console.log("routerSettingsConfig", routerSettingsConfig);

var options = {
  modules: modules,
  locales: locales,
  settingsEnv: settingsEnv,
  routerSettings: routerSettings,
  routerSettingsConfig: routerSettingsConfig,
  registerTabManager: registerTabManager,
  enableInternalCobrowse: false
}

import Vue from 'vue'
import main from './_application/main.js';
Vue.use(main, options);
