
import '@ctint/client-icons/dist/ctint-icons.css' // Ctint Icons

import clientAuthenticationServer from '@ctint/client-authentication-server'
import '@ctint/client-authentication-server/dist/client-authentication-server.css' // client-authentication-server

import ctintClientCore from '@ctint/client-core'
import '@ctint/client-core/dist/client-core.css' // client-core

import ctintClientCoreUsersession from '@ctint/client-core-usersession'
import '@ctint/client-core-usersession/dist/client-core-usersession.css' // client-core-usersession

import ctintNotification from '@ctint/client-core-notification'
import '@ctint/client-core-notification/dist/client-core-notification.css' // client-core-notification

import ctintClientMgmt from '@ctint/client-core-mgmt'
import '@ctint/client-core-mgmt/dist/client-core-mgmt.css'
// import ctintClientMgmt from '../client-core-mgmt/index'

import CtintMainTab from './views/main/mainTab/CtintMainTab.vue'
import CtintMainTabLayout from "./views/main/mainTab/layout/CtintMainTabLayout.vue"
import CtintMultipleTabCategoryMain from "./views/main/mainTab/content/CtintMultipleTabCategoryMain.vue"

export default {
  install(Vue, options) {
    Vue.component(CtintMainTab.name, CtintMainTab);
    Vue.component(CtintMainTabLayout.name, CtintMainTabLayout);
    Vue.component(CtintMultipleTabCategoryMain.name, CtintMultipleTabCategoryMain);

    Vue.use(clientAuthenticationServer, options)
    Vue.use(ctintClientCore, options)
    Vue.use(ctintClientCoreUsersession, options)
    Vue.use(ctintNotification, options)
    Vue.use(ctintClientMgmt, options)
  }
}