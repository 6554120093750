<template>
  <div>
    <ctint-contact-history-list :namespace="namespace_contacthistorylist" :params="params_contacthistorylist" :isParamsReady="isParamsReady_contacthistorylist" :isRouteContactHistoryList="isRouteContactHistoryList" @viewDetail="viewDetail" v-if="isShowContactHistoryList" />
    <ctint-contact-history-detail :namespace="namespace_contacthistorydetailall" :params="params_contacthistorydetailall" :isRouteContactHistoryDetail="isRouteContactHistoryDetail" @backToList="backToList" v-if="isShowContactHistoryDetail" />
  </div>
</template>

<script>
import { VuexStoreFactory } from "@ctint/client-core-js";
export default {
  name: "CtintContactHistoryListDetail",
  components: {},
  data() {
    return {
      namespace_contacthistorylist: "",
      namespace_contacthistorydetailall: "",
      params_contacthistorylist: {},
      params_contacthistorydetailall: {},

      isParamsReady_contacthistorylist: false,

      isShowContactHistoryDetail: false,
      isShowContactHistoryList: true,

      isRouteContactHistoryList: false,
      isRouteContactHistoryDetail: false,
    };
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/association" },
  },
  created: function () {},
  watch: {},
  mounted: function () {
    this.initParamsContactHistory();
  },
  computed: {},
  beforeDestroy() {},
  destoyed: function () {},
  methods: {
    async initParamsContactHistory() {
      this.namespace_contacthistorylist = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-contacthistory", true);
      this.params_contacthistorylist.startDate = "2021-06-20";
      this.params_contacthistorylist.endDate = "2021-07-20";
      this.params_contacthistorylist.type = "showUnassociatedList";
      this.isParamsReady_contacthistorylist = true;
    },
    viewDetail(data) {
      this.namespace_contacthistorydetailall = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-contacthistory", true);
      this.params_contacthistorydetailall.pkey = data.code;
      this.isShowContactHistoryList = false;
      this.isShowEmailList = false;
      this.isShowContactHistoryDetail = true;
    },
    backToList() {
      this.isShowContactHistoryDetail = false;
      this.isShowEmailList = true;
      this.isShowContactHistoryList = true;
    },
  },
};
</script>
