<template>
  <div v-if="layoutSubType">
    <div v-show="layoutSubType == 'single'">
      <ctint-main-tab-single namespace="ctint-main-tab/single" @openSelectedTask="openSelectedTask" :routerItems="routerItems" />
    </div>
    <div v-show="layoutSubType == 'contact'">
      <ctint-main-tab-layout-content :mode="layoutSubType" namespace="ctint-main-tab/contact" @openSelectedTask="openSelectedTask" />
    </div>
    <div v-show="layoutSubType == 'multiple'">
      <ctint-main-tab-layout-content :mode="layoutSubType" namespace="ctint-main-tab/multiple" @openSelectedTask="openSelectedTask" />
    </div>
    <div v-show="layoutSubType == 'category'">
      <ctint-main-tab-layout-content :mode="layoutSubType" namespace="ctint-main-tab/category" @openSelectedTask="openSelectedTask" />
    </div>

    <ctint-main-tab-sub :namespace="namespace" />
  </div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";

import CtintMainTabSingle from "./CtintMainTabSingle";

export default {
  name: "CtintMainTab",
  components: {
    CtintMainTabSingle: CtintMainTabSingle,
  },
  data() {
    return {};
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/single" },
    routerItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created: function () {
    console.log("CtintMainTab created");
  },
  watch: {},
  mounted: function () {},
  computed: {
    layoutSubType() {
      return this.tabmanager().getLayoutSubType();
    },
  },
  beforeDestroy() {},
  destoyed: function () {
    console.log("CtintMainTab destroyed");
  },
  methods: {
    tabmanager() {
      this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    setLayoutSubType(layoutSubType) {
      this.tabmanager().setLayoutSubType(layoutSubType);
      this.$forceUpdate();
    },
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>
<style lang="stylus">
.multiple-tab-main {
  display: flex;
  // padding-left: 50px;
  width: 80%;
  height: 100%;
}

.multiple-tab-right {
  float: right;
  width: 20%;
  height: 100%;
  padding: 10px 5px;

  >div {
    border-radius: var(--ctint-component-cardtitleradius);
  }
}
</style>
