<template>
  <div class="main-page custom-event-calendar">
    <section class="main-section main-center">
      <div class="calendar-full">
        <ctint-multiple-tab ref="tab" :namespace="namespace" mode="single" :height="50" :tabs="firstTabList" v-model="selectedFirstTab" v-on:clickTab="clickTab" />
        <ctint-event-calendar v-show="selectedFirstTab == 'calendar'" :namespace="namespace_eventcalendar" :attributes="activeAttributes" :maxEventToShow="3" @changePage="changePage" @selectedDate="selectedDate" />
      </div>
    </section>
    <section class="main-section main-right">
      <div class="calendar-full">
        <ctint-multiple-tab ref="tab" :namespace="namespace" mode="single" :height="50" :tabs="rightTabList" v-model="selectedRightTab" v-on:clickTab="clickRightTab" />
        <ctint-task-manager v-show="selectedRightTab == 'detail'" :namespace="namespace_taskmanager" :params="params_taskmanager" :isParamsReady="isParamsReady_taskmanager" :mode="taskManagerMode" :targetDate="targetDate" />
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { Vuexns, VuexStoreFactory } from "@ctint/client-core-js";

export default {
  name: "CtintCustomEventCalendar",
  components: {},
  data() {
    return {
      firstTabList: [],
      selectedFirstTab: null,
      rightTabList: [],
      selectedRightTab: null,
      creatorTaskList: [],
      activeAttributes: [],
      currentMonthObj: null,
      namespace_eventcalendar: null,
      targetDate: null,
      namespace_taskmanager: null,
      params_taskmanager: {},
      isParamsReady_taskmanager: false,
      taskManagerMode: "eventCalendar",
    };
  },
  props: {
    namespace: { type: String, default: "ctint-taskmanager" },
  },
  created: async function () {
    console.log("CtintCustomEventCalendar created");
    this.firstTabList = [
      {
        key: "calendar",
        label: this.$i18n.t("application.eventCalendar"),
      },
    ];
    this.selectedFirstTab = "calendar";
    this.rightTabList = [
      {
        key: "detail",
        label: null,
      },
    ];
    this.selectedRightTab = "detail";
    this.namespace_eventcalendar = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-taskmanager", true);
    this.namespace_taskmanager = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-taskmanager", true);
    this.isParamsReady_taskmanager = true;
  },
  mounted: async function () {},
  computed: {
    state() {
      return this.vw().state();
    },
    latestRefreshDate() {
      return this.state.latestRefreshDate;
    },
  },
  watch: {
    // for refresh calendar which is triggered by vuex
    latestRefreshDate: function () {
      console.log("refresh event calendar: " + JSON.stringify(this.currentMonthObj));
      this.initalizeTaskList(this.currentMonthObj);
      debugger;
    },
    targetDate: function () {
      if (this.targetDate) {
        this.rightTabList[0].label = moment(new Date(this.targetDate)).format("D MMM YYYY");
      }
    },
  },
  methods: {
    async initalizeTaskList(currentMonthObj) {
      await this.vw().dispatch("getListByCreator", currentMonthObj);
      this.creatorTaskList = JSON.parse(JSON.stringify(this.state.creatorTaskList));
      this.activeAttributes = JSON.parse(JSON.stringify(this.creatorTaskList));
    },
    changePage(currentMonthObj) {
      console.log("return curent month: " + JSON.stringify(currentMonthObj));
      this.currentMonthObj = currentMonthObj;
      this.initalizeTaskList(currentMonthObj);
    },
    selectedDate(date) {
      console.log("selected date: " + date);
      this.targetDate = date;
    },
    clickTab(tab) {
      console.log("clickTab", tab);
      this.selectedFirstTab = tab.key;
      this.$forceUpdate();
    },
    clickRightTab(tab) {
      console.log("clickTab", tab);
      this.selectedRightTab = tab.key;
      this.$forceUpdate();
    },
    isShowTab(key) {
      if (key == this.selectedTab) {
        return true;
      }
      return false;
    },
    vw() {
      this.vuexns = Vuexns.getOrNewInstance(this.vuexns, this.$store, this.namespace);
      return this.vuexns;
    },
  },
};
</script>
<style lang="stylus" scoped>
.custom-event-calendar >>> .main-right {
  width: 430px !important;
  min-width: 430px !important;
}

.custom-event-calendar >>> .vc-pane-layout {
  height: calc(100% - 60px) !important;
}

.custom-event-calendar >>> .vs-table--tbody {
  max-height: 100% !important;
}
</style>
