<template>
  <div class="application-main">
    <ctint-router-page-content :namespace="namespace" @openSelectedTask="openSelectedTask" :routerItems="routerItems" />
  </div>
</template>

<script>
import CtintRouterPageContent from "./content/CtintRouterPageContent";

export default {
  components: {
    CtintRouterPageContent: CtintRouterPageContent,
  },
  data() {
    return {};
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/single" },
    routerItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created() {
    console.log("CtintRouterTab created");
  },
  watch: {},
  mounted() {},
  computed: {},
  beforeDestroy() {},
  destoyed() {
    console.log("CtintRouterTab destroyed");
  },
  methods: {
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>
<style lang="stylus">
.multi-tab {
  button:focus {
    outline: none;
  }

  .vs-tabs-primary .con-ul-tabs .activeChild button, .vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
    color: var(--ctint-color-secondary) !important;
  }

  .vs-tabs--li button {
    color: var(--ctint-color-unselected);
  }

  .activeChild button.vs-tabs--btn {
    padding-top: 10px;
  }

  .vs-tabs-position-top .vs-tabs--ul {
    flex-wrap: unset;
    box-shadow: none;
  }

  .tab-slide-left, .tab-slide-right {
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 50%;
    background: var(--ctint-color-secondary);
    color: white;
    border: none;
  }

  .tab-slide-left {
    margin-left: 10px;
    left: 0;
  }

  .tab-slide-right {
    margin-right: 10px;
    right: 0;
  }
}
</style>