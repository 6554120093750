<template>
  <div>
    <ctint-contact-account-search v-if="isExistsNamespaceByItem(item, 'contactaccountsearch')" v-show="isShowTabByItem(item, 'contactaccountsearch')" :namespace="item.key" :params="item.params" />
    <ctint-customer-create-update v-if="isExistsNamespaceByItem(item, 'contactaccountupdate')" v-show="isShowTabByItem(item, 'contactaccountupdate')" :namespace="item.key" :params="item.params" />
    <ctint-customer v-if="isExistsNamespaceByItem(item, 'contactaccountdetail')" v-show="isShowTabByItem(item, 'contactaccountdetail')" :namespace="item.key" :params="item.params" />
    <ctint-contact-history v-if="isExistsNamespaceByItem(item, 'contacthistorydetail')" v-show="isShowTabByItem(item, 'contacthistorydetail')" :namespace="item.key" :params="item.params" />
  </div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";

export default {
  name: "CtintMultipleTabContent",
  components: {},
  data() {
    return {};
  },
  props: {
    namespace: { type: String, required: true },
    item: { type: Object, required: true },
  },
  created: function () {
    console.log("CtintMultipleTabContent created");
  },
  watch: {
    "item.key": function () {
      this.tabmanagerInst = null;
    },
    namespace() {
      this.tabmanagerInst = null;
    },
  },
  mounted: function () {},
  computed: {
    selectedTab() {
      return this.tabmanager().getSelectedTab();
    },
  },
  beforeDestroy() {},
  destoyed: function () {},
  methods: {
    tabmanager() {
      this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    isExistsNamespaceByItem(item, keyOrPrefix) {
      return this.tabmanager().isExistsNamespace(item, keyOrPrefix);
    },
    isShowTabByItem(item, keyOrPrefix) {
      return this.tabmanager().isShowTab(item, keyOrPrefix, this.selectedTab);
    },
  },
};
</script>