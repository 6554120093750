// cdss modules
import ctintClientToolbar from '@ctint/client-toolbar'
import '@ctint/client-toolbar/dist/client-toolbar.css'
// import ctintClientToolbar from '../client-toolbar/index'

import ctintClientContactAccount from '@ctint/client-ecca-contactaccount'
import '@ctint/client-ecca-contactaccount/dist/client-ecca-contactaccount.css'
// import ctintClientContactAccount from '../client-ecca-contactaccount/index'

import ctintClientContactHistory from '@ctint/client-ecca-contacthistory'
import '@ctint/client-ecca-contacthistory/dist/client-ecca-contacthistory.css'
// import ctintClientContactHistory from '../client-ecca-contacthistory/index'

import ctintTaskManager from '@ctint/client-core-taskmanager'
import '@ctint/client-core-taskmanager/dist/client-core-taskmanager.css'
// import ctintTaskManager from '../client-core-taskmanager/index'

import ctintClientTTSAdmin from '@ctint/client-tts-admin'
import '@ctint/client-tts-admin/dist/client-tts-admin.css'
// import ctintClientTTSAdmin from '../client-tts-admin/index'

// project modules

//component
import MainTab from "./main/MainTab.vue"
import CtintMultipleTabContent from "./main/mainTab/content/CtintMultipleTabContent.vue"
import CtintRouterPageContent from "./main/mainTab/content/CtintRouterPageContent.vue"
import CtintReportMain from "./components/CtintReportMain.vue"
import CtintContactHistory from "./components/CtintContactHistory.vue"
import CtintCustomer from "./components/CtintCustomer.vue"
import CtintCustomerCreateUpdate from "./components/CtintCustomerCreateUpdate.vue"
import CtintMainTabLayoutContent from "./main/mainTab/CtintMainTabLayoutContent.vue"
import CtintMainTabSub from "./main/mainTab/CtintMainTabSub.vue"
import CtintContactHistoryListDetail from "./components/CtintContactHistoryListDetail.vue"
import CtintCustomEventCalendar from "./components/CtintCustomEventCalendar.vue"

export default {
  install(Vue, options) {
    Vue.component(MainTab.name, MainTab);
    Vue.component(CtintMultipleTabContent.name, CtintMultipleTabContent);
    Vue.component(CtintRouterPageContent.name, CtintRouterPageContent);
    Vue.component(CtintReportMain.name, CtintReportMain);
    Vue.component(CtintContactHistory.name, CtintContactHistory);
    Vue.component(CtintCustomer.name, CtintCustomer);
    Vue.component(CtintCustomerCreateUpdate.name, CtintCustomerCreateUpdate);
    Vue.component(CtintMainTabLayoutContent.name, CtintMainTabLayoutContent);
    Vue.component(CtintMainTabSub.name, CtintMainTabSub);
    Vue.component(CtintContactHistoryListDetail.name, CtintContactHistoryListDetail);
    Vue.component(CtintCustomEventCalendar.name, CtintCustomEventCalendar);

    Vue.use(ctintClientToolbar, options);
    Vue.use(ctintClientContactAccount, options);
    Vue.use(ctintClientContactHistory, options);
    Vue.use(ctintTaskManager, options);
    Vue.use(ctintClientTTSAdmin, options);
  }
}
