<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />

    <!-- for internal cobrowse -->
    <cobrowse-application></cobrowse-application>
    <!-- -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      vueAppClasses: [],
    };
  },
  async created() {
    this.$store.state.i18n = this.$i18n;
    // for internal cobrowse
    if (!sessionStorage.getItem("cobrowseViewer")) {
      await this.$loadCobrowseFile();
      this.$onReceiveCobrowseCommand("http://localhost:3000");
    }

    if (window.ctilEventBus) {
      window.cobrowseClientScript.cobrowseEventBusOnCtilStartRecorder(this.$store);
      window.cobrowseClientScript.cobrowseEventBusOnCtilVuexUpdate();
      if (sessionStorage.getItem("cobrowseViewer")) {
        this.$router.push({ path: "/" });
      }
    }
    //
  },
  watch: {},
  methods: {
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
  },
};
</script>

<style>
#app {
  font-family: var(--ctint-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
