<template>
  <ctint-main-tab-layout :namespace="namespace" :mode="mode" @openSelectedTask="openSelectedTask">
    <template #left> </template>
    <template #middle> </template>
    <template #right> </template>
  </ctint-main-tab-layout>
</template>

<script>
export default {
  name: "CtintMainTabLayoutContent",
  components: {},
  data() {
    return {};
  },
  props: {
    namespace: { type: String, required: true },
    mode: { type: String, required: true },
  },
  created() {},
  watch: {},
  computed: {
    userId() {
      return this.$store.state["ctint-toolbar"].userId;
    },
    serverType() {
      return this.$store.state["ctint-toolbar"].serverType;
    },
  },
  mounted() {},
  methods: {
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>
<style scoped>
.task-calendar--toolbar {
  height: 655px;
}

.task-calendar--no--toolbar {
  height: 880px !important;
}
</style>
