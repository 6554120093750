<template>
  <div class="multi-component">
    <ctint-contact-history-detail :params="params_contacthistory" :namespace="namespace_contacthistory" @showConversationDetail="showConversationDetail" />
  </div>
</template>

<script>
import { VuexStoreFactory } from "@ctint/client-core-js";

export default {
  name: "CtintContactHistory",
  components: {},
  data() {
    return {
      namespace_contacthistory: "",
      agentCode: null,
      chatProxyId: null,
      contactHistoryDetail: null,
      namespace_fax: "",
    };
  },
  props: {
    namespace: { type: String, default: "ctint-icrm" },
    params: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {},
  created() {
    this.namespace_contacthistory = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-contacthistory", true);
    this.params_contacthistory = JSON.parse(JSON.stringify(this.params));
    this.params_contacthistory.instanceCloseTabKey = this.namespace; // add for close tab
    this.namespace_fax = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-fax", true);
  },
  mounted() {},
  computed: {
    faxParams() {
      var curParams = {};
      if (this.params) {
        curParams = JSON.parse(JSON.stringify(this.params));
      }

      curParams.callId = this.contactHistoryDetail.conversationCode;
      curParams.mode = "contactaccount";
      return curParams;
    },
  },
  methods: {
    showConversationDetail(contactHistory, contactHistoryDetail) {
      console.log("conversation detail: ", contactHistoryDetail);
      this.contactHistoryDetail = contactHistoryDetail;
      this.chatProxyId = contactHistoryDetail.chatProxyId;
      this.agentCode = contactHistory.agentCode;
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="stylus">
.contact-history-chat-message {
  height: 560px !important;
  width: 500px;
  overflow: auto;
  padding-top: 5px;
}
</style>
<style lang="stylus" scoped>
.contact-history-chat-message >>> .chat {
  border-radius: 20px;
}
</style>