<template>
  <div>
    <div v-if="company && company != ''">
      <ctint-navbar
        :navMenuItems="filterNavMenuItems"
        :layoutSubType="layoutSubType"
        namespace="ctint-v-nav-menu"
        @adjustNavBarPadding="adjustNavBarPadding"
        :isShowDirectory="isUsePureCloud"
        :isShowSearchCustomer="isNormalToolbar"
        :isShowStatus="isUsePureCloud"
        @searchCustomer="searchCustomer"
        :isShowUserDisplayName="!isUseSalesForce"
        :isShowLoginDuration="!isUseSalesForce"
        :mode="isUseSalesForce ? 'salesforce' : ''"
      />
      <ctint-user-settings-container v-if="isUsePureCloud || isUseSalesForce" :isPopup="!isUseSalesForce" />
      <div v-if="isUsePureCloud || isUseSalesForce">
        <ctint-toolbar v-if="isNormalToolbar" />
        <ctint-toolbar-lite v-else-if="isUseSalesForce" mode="salesforce" class="main-tab-saleforce" />
        <ctint-toolbar-lite v-else class="main-tab-left" />
        <ctint-main-tab v-if="isUsePureCloud" @openSelectedTask="openSelectedTask" :routerItems="navMenuItems" />
      </div>
      <div v-else>
        <ctint-tts-admin />
      </div>
    </div>
    <ctint-notification @notiAction="notiAction" :position="isUseSalesForce ? 'bottom-left-full' : 'top-right'" />
  </div>
</template>

<script>
export default {
  name: "MainTab",
  components: {},
  data() {
    return {};
  },
  props: {
    navMenuItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    layoutSubType: { type: String, default: null },
    selectedContactRecordId: { type: String, default: null },
    selectedContactRecord: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectedTagCloudEntries: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isNavBarActive: { type: Boolean, default: true },
  },
  async created() {},
  async mounted() {
    await this.initTabs();
  },
  computed: {
    company() {
      return this.$store.state["ctint-core-usersession"].sessionInfo.company;
    },
    isNormalToolbar() {
      if (this.company && this.company == "POLYU") {
        return true;
      }

      return false;
    },
    isUseSalesForce() {
      if (this.company == "CRYOLIFE") {
        return true;
      }
      return false;
    },
    isUsePureCloud() {
      if (this.company && (this.company == "SAMARITANS" || this.company == "POLYU")) {
        return true;
      }

      return false;
    },
    filterNavMenuItems() {
      if (this.isUsePureCloud) {
        return this.navMenuItems;
      }
      return [];
    },
  },
  watch: {},
  methods: {
    adjustNavBarPadding(value) {
      this.$emit("adjustNavBarPadding", value);
    },
    searchCustomer(content) {
      this.$emit("searchCustomer", content);
    },
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
    getIsOpenContact() {
      if (this.isNormalToolbar) {
        return true;
      }
      return false;
    },
    async notiAction(notificationItem, result) {
      this.$emit("notiAction", notificationItem, result);
    },
    async initTabs() {
      await this.$router
        .push({
          name: "cdssempty",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickTaskManagerReferenceCode(payload) {
      console.log("MainTab.clickTaskManagerReferenceCode", payload);
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="stylus">
.main-tab-left {
  height: 100%;
  width: 363px;
  float: left;
  margin: 10px 5px 5px 5px;
}

.main-tab-right {
  height: 780px;
  width: calc(99% - 363px);
  float: right;
}

.main-tab-saleforce {
  overflow: hidden;
}
</style>
<style lang="stylus" scoped>
>>>.multi-tab.black-style {
  margin-left: 5px !important;
}

>>>.ctint-nav-item.logout {
  background-color: unset;

  >button {
    background-color: unset !important;
    box-shadow: unset !important;
  }
}

>>>.salesforce .card-detail.history-tab .vs-table--tbody-table .tr-values {
  .call .title-box {
    background: unset !important;
  }

  >td {
    background: unset !important;
  }
}

>>>.salesforce .card-detail .vs-table--tbody-table .tr-values {
  >td {
    background: #bfdad8 !important;
  }
}

.layout--main {
  overflow: scroll !important;
  position: fixed;
  height: 100vh;
}

>>>.sc-directory {
  position: unset;
  padding-bottom: 10px;

  .directory {
    width: unset;
    margin-left: 10px;
    margin-right: 5px;
  }
}
</style>
