import App from './App.vue'
import AppCobrowse from './AppCobrowse.vue'
import store from './stores/store.js'
import { UserSessionStore, clientLogUtils } from "@ctint/client-core-js"

store.subscribe(({ type, payload }) => {
  console.log('subscribe type:', type)
  console.log('subscribe payload:', payload)
  switch (type) {
    case 'ctint-core-usersession/SET_SESSION_INFO': {
      const userInfo = payload.userInfo;
      UserSessionStore.getInstance().updateCurrentUserInfo(payload.sessionInfo, payload.userInfo);
      UserSessionStore.getInstance().syncToState(store.state);
      var result = store.dispatch('ctint-v-nav-menu/updateUserInfo', { ...userInfo });
      if (store.state["ctint-email-box"]) {
        store.dispatch('ctint-email-box/login');
      }
      return result;
    }
  }
})

store.subscribeAction(async ({ type }) => {
  switch (type) {
    case 'ctint-v-nav-menu/logout':
      try {
        if (store.state["ctint-email-box"]) {
          store.dispatch("ctint-email-box/logout");
        }
      } catch (err) { console.log(err); }
      var result = store.dispatch("ctint-core-usersession/logout");
      return result;
    case 'ctint-core-usersession/checkSession':
      if (store.state["ctint-toolbar"]) {
        return store.dispatch('ctint-toolbar/checkSession')
      }
      return true;
  }
})

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.min.css' // bootstrap

// Vue I18n
import VueI18n from 'vue-i18n';

// Vue Axios
import './axios';

//able to scroll horizontally
import '@koas/v-hscroll';

// Vue Router
import { initializeRouter } from './routerMainTab';
import routerSettings from './routerSettings';
import routerSettingsConfig from './routerSettingsConfig';

//locales
var availableLocales = ["en"];
var localeMessages = {};
var vueI18n;
var settingsEnv = {};

var addLocaleMessages = function (context) {
  if (context) {
    var keys = context.keys();
    for (var i in keys) {
      var locale = keys[i].match(/[a-z0-9-_]+/i)[0];
      if (availableLocales.includes(locale)) {
        var messages = context(keys[i]);
        //console.log(locale, messages);
        if (messages) {
          if (!localeMessages[locale]) {
            localeMessages[locale] = messages;
          }
          else {
            for (var key in messages) {
              if (!localeMessages[locale][key]) {
                localeMessages[locale][key] = messages[key];
              } else {
                for (var subKey in messages[key]) {
                  localeMessages[locale][key][subKey] = messages[key][subKey]
                }
              }
            }
          }
        }
      }
    }
  }
}

var onSessionTimeout = async function (url, ex) {
  if (sessionStorage.getItem("cobrowseViewer")) {
    return;
  }

  if (!window["revcieSessionTimeOut"] || window["revcieSessionTimeOut"] == 0) {
    window["revcieSessionTimeOut"] = 1;
    console.log("revcieSessionTimeOut", url, ex);

    var isAutoLoginForUserSessionTimeout = false;
    var isShowSessionTimeoutAlert = true;
    UserSessionStore.getInstance().syncToState(store.state);

    if (store.state && store.state.userSession && store.state.userSession.isAutoLoginForUserSessionTimeout) {
      isAutoLoginForUserSessionTimeout = store.state.userSession.isAutoLoginForUserSessionTimeout;
    }
    if (isAutoLoginForUserSessionTimeout) {
      if (!window["processingAutoLogin"] || window["processingAutoLogin"] == 0) {
        window["processingAutoLogin"] = 1;
        var result = store.state["ctint-core-usersession"].autoLoginResult;
        if (result && result.isTerminate) {
          isShowSessionTimeoutAlert = true;
        }
        else if (result && result.isSuccess) {
          isShowSessionTimeoutAlert = false;
        }
        else {
          result = await store.dispatch("ctint-core-usersession/autoLogin");
          console.log("onSessionTimeout result:", result);
          if (!result || result.isSuccess || !result.isTerminate) {
            isShowSessionTimeoutAlert = false;
          }
        }
      }
    }
    console.log("onSessionTimeout isShowSessionTimeoutAlert:", isShowSessionTimeoutAlert);
    if (isShowSessionTimeoutAlert) {
      var errorMessage = vueI18n.t("application.userSessionTimeout");
      if (errorMessage && errorMessage != "") {
        alert(errorMessage);
      }
      if (store) {
        if (store.state["ctint-toolbar"]) {
          if (store.getters["ctint-toolbar/isLoggedIn"]) {
            store.dispatch("ctint-toolbar/logout");
          }
        }
        store.dispatch("ctint-v-nav-menu/logout");
      }
    }
  }
}

import settings from './settings';

import { changeEnvSetting } from './settings';

import modules from './modules.js';

import { version } from '../../package.json'

var onRegisterModuleComplete = function ($store, registerNamespace, namespace, state) {
  changeEnvSetting(state.hostSettings, settingsEnv);
}

export default {
  install(Vue, options) {

    Vue.use(Vuesax);

    Vue.use(VueI18n);

    // must keep to use routerSettings
    // if pRouterSettings exists, use it as first item, so that project setting will override _application settings
    var pRouterSettings = options.routerSettings;
    if (pRouterSettings) {
      let pRouterNameList = pRouterSettings.map(function (a) { return a.name; });
      for (var i = 0; i < routerSettings.length; i++) {
        var routerSetting = routerSettings[i];
        if (pRouterNameList.includes(routerSetting.name) == true) {
          routerSettings.splice(i, 1);
          i--;
        }
      }
      routerSettings.push(...pRouterSettings);
    }

    if (options.routerSettingsConfig) {
      for (var key in options.routerSettingsConfig) {
        routerSettingsConfig[key] = options.routerSettingsConfig[key];
      }
    }

    settingsEnv = options.settingsEnv;

    console.log("routerSettingsConfig", routerSettingsConfig);

    const mainOptions = {
      store: store,
      routerSettings: routerSettings,
      onSessionTimeout: onSessionTimeout,
      addLocaleMessages: addLocaleMessages,
      onRegisterModuleComplete: onRegisterModuleComplete
    }

    // Event Bus
    window.EventBus = new Vue({});

    Vue.use(modules, mainOptions);

    Vue.use(options.modules, mainOptions);

    var appDom = document.getElementById('app');
    var envAttr = appDom.attributes.getNamedItem('env')
    var env = null
    if (envAttr) {
      env = envAttr.value
    }

    // update hostSettings server
    Vue.use(settings, { store: store, env: env, settingsEnv: options.settingsEnv, onRegisterModuleComplete: onRegisterModuleComplete });

    console.log("mainOptions.routerSettings", mainOptions.routerSettings);
    var router = initializeRouter(mainOptions.routerSettings, options.registerTabManager);

    var context = require.context("./locales", false, /[a-z0-9-_]+\.json$/i);
    addLocaleMessages(context);
    console.log("localeMessages", localeMessages);

    addLocaleMessages(options.locales);
    console.log("localeMessages", localeMessages);

    vueI18n = new VueI18n({
      locale: 'en',
      fallbackLocale: 'en',
      messages: localeMessages,
      silentTranslationWarn: true
    })

    Vue.config.productionTip = false;

    Vue.config.errorHandler = function (err, vm, info) {
      var name = "errorHandler" + (info ? "-" + info : "");
      clientLogUtils.addErrorLog(name, err);
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
      clientLogUtils.addErrorLog("warnHandler", msg, trace);
    }

    store.state.i18n = vueI18n;
    store.state.store = store;

    store.state.version = version;

    var meta = document.createElement('meta');
    meta.name = "CDSS_VERSION";
    meta.content = version;
    document.getElementsByTagName('head')[0].appendChild(meta);

    if (options.enableInternalCobrowse) {
      new Vue({
        router,
        store,
        i18n: vueI18n,
        render: h => h(AppCobrowse),
      }).$mount('#app')
    } else {
      new Vue({
        router,
        store,
        i18n: vueI18n,
        render: h => h(App),
      }).$mount('#app')
    }
  }
}