import { VuexStoreFactory } from '@ctint/client-core-js'

var env = 'LOCALHOST'

export default {

  install(Vue, options) {
    if (!Vue) {
      throw new Error('Please initialise Vue.')
    }
    if (!options || !options.store) {
      throw new Error('Please initialise with a Vuex store.')
    }
    let store = options.store;
    env = options.env ? options.env : env;
    let settingsEnv = options.settingsEnv;

    for (let key in store._modulesNamespaceMap) {
      let namespace = key.substring(0, key.length - 1);
      let hostSettings = store.state[namespace].hostSettings;
      if (hostSettings != null && hostSettings != undefined) {
        changeEnvSetting(hostSettings, settingsEnv);
      }
    }

    var authClientHost = _getEnvSettingValueByKey(settingsEnv, "authClientHost");
    if (authClientHost) {
      store.state.authClientHost = authClientHost;
    }

    VuexStoreFactory.getInstance(store).onRegisterModuleComplete = options.onRegisterModuleComplete;
  }
}

export function changeEnvSetting(stateHostSettings, settingsEnv) {
  //console.log("stateHostSettings", stateHostSettings);
  if (stateHostSettings && settingsEnv) {
    //console.log("changeEnvSetting", settingsEnv);
    if (settingsEnv.hostSettings) {
      var hostSettings = settingsEnv.hostSettings;
      if (settingsEnv.hostSettings[env]) {
        hostSettings = settingsEnv.hostSettings[env];
      }
      for (var key in stateHostSettings) {
        if (hostSettings[key]) {
          stateHostSettings[key] = hostSettings[key];
          //console.log("changeEnvSetting", key, "change To ", stateHostSettings[key]);
        }
      }
    }
  }
}

function _getEnvSettingValueByKey(settingsEnv, key) {
  if (settingsEnv.hostSettings && settingsEnv.hostSettings[env] && settingsEnv.hostSettings[env][key]) {
    return settingsEnv.hostSettings[env][key];
  }
  return null;
}

