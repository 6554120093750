<template>
  <div></div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";

export default {
  name: "CtintMainTabSub",
  components: {},
  data() {
    return {};
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/single" },
  },
  created: function () {
    console.log("CtintMainTabSub created");
  },
  watch: {},
  mounted: function () {},
  computed: {
    layoutSubType() {
      return this.tabmanager().getLayoutSubType();
    },
  },
  beforeDestroy() {},
  destoyed: function () {
    console.log("CtintMainTabSub destroyed");
  },
  methods: {
    tabmanager() {
      this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>
