<template>
  <div class="main-page">
    <slot name="left"></slot>
    <section class="main-section main-center" v-show="mode == 'multiple' || mode == 'contact' || mode == 'category'">
      <div class="main-center-block" v-show="!showEmptyBgIfNoAvailableTabs">
        <ctint-multiple-tab-content v-show="mode == 'multiple' || mode == 'contact'" :namespace="namespace" :mode="mode" :showEmptyBgIfNoAvailableTabs="showEmptyBgIfNoAvailableTabs" />
        <ctint-multiple-tab-category-main v-show="mode == 'category'" :namespace="namespace" :mode="mode" />
      </div>
      <div class="empty-bg-display" v-if="showEmptyBgIfNoAvailableTabs" />
    </section>

    <slot name="middle"></slot>

    <slot name="right"></slot>
  </div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";

import CtintMultipleTabContent from "../content/CtintMultipleTabContent";
import CtintMultipleTabCategoryMain from "../content/CtintMultipleTabCategoryMain";

export default {
  name: "CtintMainTabLayout",
  components: {
    CtintMultipleTabContent: CtintMultipleTabContent,
    CtintMultipleTabCategoryMain: CtintMultipleTabCategoryMain,
  },
  data() {
    return {};
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/multiple" },
    mode: { type: String, default: "" },
  },
  created() {
    console.log("CtintMainTabLayout created");
  },
  watch: {
    mode() {
      this.tabmanagerInst = null;
    },
    namespace() {
      this.tabmanagerInst = null;
    },
  },
  mounted() {},
  computed: {
    selectedTab() {
      return this.tabmanager().getSelectedTab();
    },
    selectedGroup() {
      return this.tabmanager().getSelectedGroup();
    },
    tabList() {
      return this.tabmanager().getTabList();
    },
    layoutCategory() {
      return this.tabmanager().getLayoutCategory();
    },
    showEmptyBgIfNoAvailableTabs() {
      if (this.tabList && this.tabList[0]) {
        return false;
      } else if (this.$slots && ((this.$slots.middle && this.$slots.middle[0]) || (this.$slots.right && this.$slots.right[0]))) {
        return false;
      }
      return true;
    },
  },
  methods: {
    tabmanager() {
     this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    clickTab(tab) {
      console.log("click tab", tab);
      this.tabmanager().setSelectedTab(tab.key);
      this.tabmanager().setLayoutCategory(tab.layoutCategory);
    },
    closeTab(tab) {
      console.log("close tab", tab);
      this.tabmanager().closeTab(tab.key);
    },
    isShowGroupTab(item) {
      return this.tabmanager().isShowGroupTab(item);
    },
    isShowContent(item) {
      if (item.layoutCategory == this.layoutCategory) {
        return true;
      } else {
        return false;
      }
    },
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>

<style lang="stylus">
.main-page {
  display: flex;
  padding: 10px 5px;

  >section {
    >div {
      border-radius: var(--ctint-component-cardtitleradius);
      box-shadow: 0px 2px 12px rgba(39, 39, 39, 0.4);
    }
  }

  section {
    &.main-section {
      float: left;

      &.main-left {
        width: 523px;
        min-width: 523px;
      }

      &.main-center {
        flex: 1;
        margin: 0 10px;
      }

      &.main-right {
        width: 425px;
        min-width: 425px;
      }

      &.main-left, &.main-right {
        >div:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.main-center-no-block {
  box-shadow: none !important;
}

.main-center-block {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  >div {
    height: 880px;
    display: flex;
    flex-direction: column;
    background: var(--ctint-color-secondaryaccent);
  }
}

.saa {
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  height: 652px;

  >div:first-child {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.wallboard-dialogue {
  // max-width: 394px;
  height: 218px;
  margin: auto;
}

.calendar-full {
  background: #fff;
  width: 100%;
  height: 880px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.main-center-block-scroll {
  height: 100%;
  width: 100%;
  position: relative;
}

.empty-bg-display {
  flex: 0;
  height: 880px;
  width: 100%;
  background-image: url('../../../../assets/images/ctint-cdss-group-bg.png');
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
}
</style>
