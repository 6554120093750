<template>
  <div>
    <ctint-contact-account-update :namespace="namespace" :params="contactAccountParam"> </ctint-contact-account-update>
  </div>
</template>

<script>
import { VuexStoreFactory } from "@ctint/client-core-js";

export default {
  name: "CtintCustomerCreateUpdate",
  components: {},
  data() {
    return {
      namespace_icrm: "",
    };
  },
  props: {
    namespace: { type: String, default: "ctint-icrm" },
    params: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {},
  created() {
    this.namespace_icrm = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "sc-customer", true);
  },
  mounted() {},
  computed: {
    company() {
      return this.$store.state["ctint-core-usersession"].sessionInfo.company;
    },
    contactAccountParam() {
      var p = JSON.parse(JSON.stringify(this.params));
      p.icrmNamespace = this.namespace_icrm;
      p.requireConfig = { isRequireIdentifyNo: false };
      return p;
    },
  },
  methods: {},
  beforeDestroy() {},
};
</script>

