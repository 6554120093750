<template>
  <div>
    <div>
      <ctint-multiple-tab-second-level
        :namespace="namespace"
        :mode="mode"
        :tabs="tabList"
        :canClose="true"
        v-model="selectedTab"
        v-on:clickTab="clickTab"
        v-on:closeTab="closeTab"
        :selectedGroup="selectedGroup"
        :isShowGroupTab="isShowGroupTab"
        :layoutCategory="layoutCategory"
      ></ctint-multiple-tab-second-level>
    </div>
    <template v-for="item in tabList">
      <div :key="getTabListKey(item)">
        <ctint-multiple-tab-content :namespace="namespace" :item="item" />
      </div>
    </template>
  </div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";

export default {
  components: {},
  data() {
    return {
      /** for internal cobrowse */
      cobrowseDataConfig: [
        { id: "clickTab", functionName: "clickTab" },
        { id: "closeTab", functionName: "closeTab" },
        { id: "ctint-main-tab", blockMonitorStore: "ctint-main-tab" },
      ],
    };
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/category" },
    mode: { type: String, default: "multiple" },
  },
  created: function () {
    console.log("CtintMultipleTabCategoryContent created");
    /** for internal cobrowse */
    if (window.cobrowseClientScript) {
      window.cobrowseClientScript.initCobrowse(this, this.namespace, this.cobrowseDataConfig);
    }
  },
  watch: {
    mode() {
      this.tabmanagerInst = null;
    },
    namespace() {
      this.tabmanagerInst = null;
    },
  },
  mounted: function () {
    console.log("CtintMultipleTabCategoryContent mounted");
  },
  computed: {
    selectedTab() {
      return this.tabmanager().getSelectedTab();
    },
    selectedGroup() {
      return this.tabmanager().getSelectedGroup();
    },
    tabList() {
      return this.tabmanager().getTabList();
    },
    layoutCategory() {
      return this.tabmanager().getLayoutCategory();
    },
  },
  beforeDestroy() {},
  destoyed: function () {
    console.log("CtintMultipleTab destroyed");
  },
  methods: {
    tabmanager() {
      this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    clickTab(tab) {
      console.log("click tab", tab);
      this.tabmanager().setSelectedTab(tab.key);
    },
    closeTab(tab) {
      console.log("close tab", tab);
      this.tabmanager().closeTab(tab.key);
    },
    isShowGroupTab(item) {
      return this.tabmanager().isShowGroupTab(item);
    },
    getTabListKey(item) {
      if (item.params) {
        if (item.params.instanceRefreshIndex) {
          var key = item.key + "-" + item.params.instanceRefreshIndex;
          return key;
        }
      }
      return item.key;
    },
  },
};
</script>
<style lang="stylus">
.multi-tab {
  button:focus {
    outline: none;
  }

  .vs-tabs-primary .con-ul-tabs .activeChild button, .vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
    color: var(--ctint-color-secondary) !important;
  }

  .vs-tabs--li button {
    color: var(--ctint-color-unselected);
  }

  .activeChild button.vs-tabs--btn {
    padding-top: 10px;
  }

  .vs-tabs-position-top .vs-tabs--ul {
    flex-wrap: unset;
    box-shadow: none;
  }

  .tab-slide-left, .tab-slide-right {
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 50%;
    background: var(--ctint-color-secondary);
    color: white;
    border: none;
  }

  .tab-slide-left {
    margin-left: 10px;
    left: 0;
  }

  .tab-slide-right {
    margin-right: 10px;
    right: 0;
  }
}
</style>