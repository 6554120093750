<template>
  <div></div>
</template>

<script>
export default {
  name: "CtintRouterPageContent",
  components: {},
  data() {
    return {};
  },
  props: {
    routerPage: { type: String, default: null },
    routerItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created() {
    console.log("CtintRouterPageContent created");
  },
  watch: {},
  mounted() {},
  computed: {},
  beforeDestroy() {},
  destoyed() {},
  methods: {
    isExists(key) {
      let list = this.routerItems.filter((i) => i.slug == key);
      if (list && list[0]) {
        return true;
      }
      return false;
    },
    openSelectedTask(selectedContent, action) {
      this.$emit("openSelectedTask", selectedContent, action);
    },
  },
};
</script>