<template>
  <div class="multi-component fix-customer-height">
    <ctint-contact-account-detail :params="contactAccountParam" :namespace="namespace_contactaccount"> </ctint-contact-account-detail>
    <hr />
    <div class="tab-content-scroll-div tab-content-flex">
      <ctint-contact-history-list :params="params" :namespace="namespace_contacthistory" />
    </div>
  </div>
</template>

<script>
import { VuexStoreFactory } from "@ctint/client-core-js";

export default {
  name: "CtintCustomer",
  components: {},
  data() {
    return {
      namespace_contactaccount: "",
      namespace_taskmanager: "",
      namespace_contacthistory: "",
      namespace_icrm: "",
    };
  },
  props: {
    namespace: { type: String, default: "ctint-icrm" },
    params: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {},
  created() {
    this.namespace_contactaccount = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-contactaccount", true);
    this.namespace_contacthistory = VuexStoreFactory.getInstance(this.$store).registerNestedModule(this.namespace, "ctint-contacthistory", true);

    if (this.params.ecrmCode) {
      this.$router
        .push({
          name: "ecrmcontactaccountdetail",
          params: {
            instanceCloseTabKey: this.params.instanceCloseTabKey,
            instanceSuffix: this.params.instanceSuffix,
            instanceTitle: "Customer Profile",
            instanceSelectGroupKey: "contactaccountdetail",
            instanceAutoSelect: false,
            ecrmCode: this.params.ecrmCode,
            contactAccountDetail: this.params.contactAccountDetail,
          },
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log("CtintCustomer created");
  },
  mounted() {},
  computed: {
    company() {
      return this.$store.state["ctint-core-usersession"].sessionInfo.company;
    },
    contactAccountParam() {
      var p = JSON.parse(JSON.stringify(this.params));
      p.icrmNamespace = this.namespace_icrm;
      return p;
    },
  },
  methods: {},
  beforeDestroy() {},
};
</script>

