
export default [
    {
        key: "cdssempty",
        name: "Home",
        langKey: "",
        path: "/purecloud",
        component: () => import('./main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        isSwitchLayoutSubType: true,
        isMenuItem: true,
        menuItemIcon: "dashboard",
        menuItemIsPin: true,
        storeNamespace: "",
    }
]
