
export default [
    {
        key: "login",
        path: "/pages/login",
        component: () => import('./views/fullPage/pages/AuthLogin.vue'),
        layout: "fullpage",
        isMenuItem: false,
    },
    {
        key: "authServer",
        path: "/pages/authServer",
        component: () => import('./views/fullPage/pages/AuthServer.vue'),
        layout: "fullpage",
        isMenuItem: false,
    },
    {
        key: "purecloudcallback",
        path: "/purecloudcallback",
        component: () => import('./views/fullPage/pages/AuthServerCallback.vue'),
        layout: "fullpage",
        isMenuItem: false,
    },
    {
        key: "contactaccountsearch",
        name: "Search Result",
        path: "/ecca/contactaccount/search",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contactaccountsearch', 'toolbarcampaign'],
        isMenuItem: false,
        storeNamespace: "ctint-contactaccount",
    },
    {
        key: "contactaccountdetail",
        name: "Customer Detail",
        path: "/ecca/contactaccount/:code/view",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contactaccountdetail', 'toolbarcampaign'],
        isMenuItem: false,
        storeNamespace: "ctint-contactaccount",
    },
    {
        key: "contactaccountupdate",
        name: "Create Customer",
        path: "/ecca/contactaccount/:code/update",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contactaccountupdate', 'contactaccountsearch', 'toolbarcampaign'],
        isMenuItem: false,
        storeNamespace: "ctint-contactaccount",
    },
    {
        key: "contacthistorydetail",
        name: "Contact History Detail",
        path: "/contacthistory/:code",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contacthistorydetail'],
        isMenuItem: false,
        storeNamespace: "ctint-contacthistory",
    },
    {
        key: "ecrmcontactaccountdetail",
        name: "ECRM Sample Page",
        path: "/ecca/ecrmcontactaccount/:ecrmCode/view",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contactaccountdetail', 'contactaccountupdate', 'tasklogger', 'contacthistorydetail'],
        isMenuItem: false,
        menuItemIcon: "tab",
        menuItemIsPin: false,
        storeNamespace: "",
    },
    {
        key: "ecrmcontactaccountcreate",
        name: "Ecrm Update Customer",
        langKey: "application.ecrmUpdateCustomer",
        path: "/ecca/ecrmcontactaccount/create",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['contactaccountdetail', 'contactaccountupdate', 'tasklogger', 'contacthistorydetail'],
        isMenuItem: false,
        menuItemIcon: "tab",
        menuItemIsPin: false,
        storeNamespace: "",
    },
    {
        key: "taskdetail",
        name: "Task Detail",
        path: "/taskdetail/:code/view",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['taskdetail'],
        isMenuItem: false,
        menuItemIcon: "tab",
        menuItemIsPin: false,
        storeNamespace: "ctint-taskmanager",
    },
    {
        key: "tasklogger",
        name: "Create Task",
        path: "/tasklogger/:code/update",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "contact",
        groupKeyList: ['tasklogger'],
        isMenuItem: false,
        menuItemIcon: "tab",
        menuItemIsPin: false,
        storeNamespace: "ctint-taskmanager",
    },
    {
        key: "people_alt",
        name: "people_alt",
        path: "/layout_single",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "single",
        isMenuItem: false,
        menuItemIcon: "people_alt",
        menuItemIsPin: false,
    },
    {
        key: "weekly_calendar_page",
        name: "weekly_calendar_page",
        path: "/layout_single",
        component: () => import('./views/main/pages/Empty.vue'),
        layout: "main",
        layoutSubType: "single",
        isMenuItem: false,
        menuItemIcon: "weekly_calendar_page",
        menuItemIconClass: 'ctint-icons',
        menuItemIsPin: false,
    },
    {
        key: "emailDetail",
        path: "/emailDetail",
        component: () => import("./views/fullPage/pages/EmailDetail.vue"),
        layout: "sessionfullpage",
        isMenuItem: false,
    },
    {
        key: "emailContent",
        path: "/emailContent",
        component: () => import("./views/fullPage/pages/EmailContent.vue"),
        layout: "sessionfullpage",
        isMenuItem: false,
    },
    {
        key: "faxDetail",
        path: "/faxDetail",
        component: () => import("./views/fullPage/pages/FaxDetail.vue"),
        layout: "sessionfullpage",
        isMenuItem: false,
    },
    {
        key: "faxContent",
        path: "/faxContent",
        component: () => import("./views/fullPage/pages/FaxContent.vue"),
        layout: "sessionfullpage",
        isMenuItem: false,
    }
]
