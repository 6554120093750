
import Vue from 'vue';
import Router from 'vue-router';
import store from './stores/store';
import { RSRegister } from '@ctint/client-core-js';
import TabRouter from '@ctint/client-core-tabmanager-js/src/tabmanager/TabRouter';

Vue.use(Router);

var router = null;
var routerSettings = null;

export function initializeRouter(inRouterSettings, moduleRegisterTabManager) {
  routerSettings = inRouterSettings;

  var tabRouter = TabRouter.registerModule(store, "ctint-main-tab");
  tabRouter.initialize("main", "single");
  tabRouter.registerTabManager("multiple", true);
  tabRouter.registerTabManager("contact", true);
  tabRouter.registerTabManager("single", false);
  tabRouter.registerTabManager("category", true);
  moduleRegisterTabManager(tabRouter);

  var routesConfig = [
    {
      // MAIN LAYOUT ROUTES
      path: '',
      name: 'main',
      component: () => import('./views/main/MainTab.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          name: '__default__',
          component: () => import('./views/main/pages/Empty.vue')
        },
      ],
    },
    // FULL PAGE LAYOUTS
    {
      path: '',
      name: 'fullpage',
      component: () => import('./views/fullPage/FullPage.vue'),
      children: [
        // PAGES
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/fullPage/pages/Error404.vue')
        },
      ]
    },
    {
      path: '',
      name: 'sessionfullpage',
      component: () => import('./views/fullPage/SessionFullPage.vue'),
      children: [
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      name: 'errorpage',
      redirect: '/pages/error-404'
    }
  ]

  RSRegister.getInstance().addRouterSettingsToRoutes(routerSettings, routesConfig);

  router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes: routesConfig
  })

  store.watch(
    (state, getters) => getters['ctint-core-usersession/isLoggedIn'],
    watchIsLoggedIn
  )

  router.beforeEach(async (to, from, next) => {
    //FIXME: 2021/5/31: A way to define title
    //document.title = "CDSS V2 Application"

    // cobrowse
    if (window.cobrowseClientScript && sessionStorage.getItem('cobrowse')) {
      window.cobrowseClientScript.sendCobrowseRoute(to)
    }
    if (window.cobrowseClientScript && !sessionStorage.getItem('cobrowseViewer') && !sessionStorage.getItem('cobrowse')) {
      window.cobrowseClientScript.saveCobrowseRouteForStart(to) // save the routing data for cobrowse start page
    }

    /** for duliplcate tabs found in browser */
    if (sessionStorage.getItem('duplicate-tab')) {
      // sessionStorage.removeItem('duplicate-tab');
      if (to.name.includes("errorpage") || to.name.includes("error-404")) {
        next()
      } else {
        next({
          name: "errorpage"
        })
      }
    } else {
      let page = null;
      page = await _routeToLogin(to, from);
      if (page != null) next(page);
      page = await _routeToTabRouter(to, from);
      if (page != null) next(page);
      next();
    }
  })

  router.afterEach(() => {

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = "none";
    }
  })

  return router;
}

export function watchIsLoggedIn(isLoggedIn) {
  console.log("watchIsLoggedIn", isLoggedIn);
  if (isLoggedIn) {
    if (router.currentRoute.name && router.currentRoute.name.includes('login')) {
      console.log(router)
      console.log(window.location.href)
      // router.replace('/')
      if (router.currentRoute.query.redirect !== undefined) {
        const redirect = router.currentRoute.query.redirect
        router.push(redirect)
      } else {
        router.push('/')
      }
    }
  } else {
    if (router.history.pending && router.history.pending.name === 'login') {
      console.log(router)
      console.log(window.location.href)
    }
    else {
      console.log(router)
      console.log(window.location.href)
      router.go('/pages/login')
    }
  }
}

async function _routeToLogin(to, from) {
  console.log('store:', store)

  await store.dispatch('ctint-core-usersession/checkSession')
  // const isLoggedIn = store.getters['ctint-core-usersession/isLoggedIn'] // cobrowse--
  // const isLoggedIn = true;
  // cobrowse
  var isLoggedIn = null;
  if (sessionStorage.getItem('cobrowseViewer')) {
    isLoggedIn = true;
  } else {
    isLoggedIn = store.getters['ctint-core-usersession/isLoggedIn']
  }

  if (to.name.includes('login')) {
    if (isLoggedIn) {
      return from.path;
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      return {
        name: 'login',
        query: { redirect: to.fullPath }
      };
    }
    return null;
  }
}

async function _routeToTabRouter(to) { // ,from
  let result = await TabRouter.getInstance(store, "ctint-main-tab").routeTo(to, routerSettings);
  if (result == false) {
    return null;
  }
  else {
    return {
      name: '__default__',
    }
  }
}
