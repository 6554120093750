<template>
  <ctint-report :isReportScrollX="false"> </ctint-report>
</template>


<script>
export default {
  name: "CtintReportMain",
  components: {},
  data() {
    return {};
  },
  props: {},
  created: async function () {
    console.log("CtintReportMain created");
  },
  mounted: async function () {},
  computed: {},
  methods: {},
};
</script>
