<template>
  <div class="application-main">
    <div>
      <ctint-multiple-tab ref="tab" :namespace="namespace" :mode="mode" :height="50" :tabs="tabList" v-model="selectedTab" v-on:clickTab="clickTab" v-on:closeTab="closeTab" :selectedGroup="selectedGroup" :isShowGroupTab="isShowGroupTab" />
    </div>
    <template v-for="item in tabList">
      <template v-if="!item.isHybridCategory && layoutCategory">
        <div :key="item.key" v-show="isShowContent(item)">
          <ctint-multiple-tab-category-content v-if="isShowContent(item)" v-show="isShowContent(item)" :namespace="namespace + '-' + layoutCategory" :mode="mode"></ctint-multiple-tab-category-content>
        </div>
      </template>
      <template v-else>
        <div :key="getTabListKey(item)">
          <ctint-multiple-tab-content :namespace="namespace" :item="item" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import TabManager from "@ctint/client-core-tabmanager-js/src/tabmanager/TabManager";
import CtintMultipleTabCategoryContent from "./CtintMultipleTabCategoryContent";

export default {
  name: "CtintMultipleTabCategoryMain",
  components: {
    CtintMultipleTabCategoryContent: CtintMultipleTabCategoryContent,
  },
  data() {
    return {
      /** for internal cobrowse */
      cobrowseDataConfig: [
        { id: "clickTab", functionName: "clickTab" },
        { id: "closeTab", functionName: "closeTab" },
      ],
    };
  },
  props: {
    namespace: { type: String, default: "ctint-main-tab/category" },
    mode: { type: String, default: "multiple" }, // options: multiple / contact
  },
  created: function () {
    console.log("CtintMultipleTabCategoryMain created");
    /** for internal cobrowse */
    if (window.cobrowseClientScript) {
      window.cobrowseClientScript.initCobrowse(this, this.namespace, this.cobrowseDataConfig);
    }
  },
  watch: {
    mode() {
      this.tabmanagerInst = null;
    },
    namespace() {
      this.tabmanagerInst = null;
    },
    layoutCategory: function (val) {
      this.tabmanagerInst = null;
      // for checking the category tab when 2nd level tabs have been changed
      if (val != null && val != undefined && this.selectedTab != null) {
        var currentTab = this.tabmanager().getTabByKey(this.selectedTab);
        if (currentTab.layoutCategory != val) {
          var selectKey = this.tabmanager().getCategoryTabByLayoutCategory(val);
          this.tabmanager().setSelectedTab(selectKey);
        }
      }
    },
  },
  mounted: function () {
    console.log("CtintMultipleTabCategoryMain mounted");
  },
  computed: {
    selectedTab() {
      return this.tabmanager().getSelectedTab();
    },
    selectedGroup() {
      return this.tabmanager().getSelectedGroup();
    },
    tabList() {
      return this.tabmanager().getTabList();
    },
    layoutCategory() {
      return this.tabmanager().getLayoutCategory();
    },
  },
  beforeDestroy() {},
  destoyed: function () {
    console.log("CtintMultipleTabCategoryMain destroyed");
  },
  methods: {
    tabmanager() {
     this.tabmanagerInst = TabManager.getOrNewInstance(this.tabmanagerInst, this.$store, this.namespace);
      return this.tabmanagerInst;
    },
    clickTab(tab) {
      console.log("click tab", tab);
      this.tabmanager().setSelectedTab(tab.key);
      this.tabmanager().setLayoutCategory(tab.layoutCategory);
    },
    closeTab(tab) {
      console.log("close tab", tab);
      this.tabmanager().closeTab(tab.key);
    },
    isShowGroupTab(item) {
      return this.tabmanager().isShowGroupTab(item);
    },
    isShowContent(item) {
      if (item.layoutCategory == this.layoutCategory) {
        return true;
      } else {
        return false;
      }
    },
    getTabListKey(item) {
      if (item.params) {
        if (item.params.instanceRefreshIndex) {
          var key = item.key + "-" + item.params.instanceRefreshIndex;
          return key;
        }
      }
      return item.key;
    },
  },
};
</script>
<style lang="stylus">
.multi-tab {
  button:focus {
    outline: none;
  }

  .vs-tabs-primary .con-ul-tabs .activeChild button, .vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
    color: var(--ctint-color-secondary) !important;
  }

  .vs-tabs--li button {
    color: var(--ctint-color-unselected);
  }

  .activeChild button.vs-tabs--btn {
    padding-top: 10px;
  }

  .vs-tabs-position-top .vs-tabs--ul {
    flex-wrap: unset;
    box-shadow: none;
  }

  .tab-slide-left, .tab-slide-right {
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 50%;
    background: var(--ctint-color-secondary);
    color: white;
    border: none;
  }

  .tab-slide-left {
    margin-left: 10px;
    left: 0;
  }

  .tab-slide-right {
    margin-right: 10px;
    right: 0;
  }
}
</style>